import React from "react";
import RateHeader from "./RateHeader";

const Rate80VollSchutz = () => {

    return (
        <div class="container">
            <RateHeader/>
            <div class="row">
        <iframe src="https://makler.deutsche-familienversicherung.de/spezial/tierkrankenschutz/hunde/antrag/?cm_mmc=014407&cIdent=ewogICJmdW5jdGlvbiI6IHsKICAgICJmZWVDYWxjIjogdHJ1ZSwKICAgICJmb3JtRmlsbCI6IHRydWUKICB9LAogICJpbnN1cmFuY2UiOiB7CgkiZXJzdGF0dHVuZyI6IDgwCiAgfQp9"
                style={{overflow: 'hidden', height: '170vh', width: '100%'}}></iframe>
        </div>
</div>
    )

}

export default Rate80VollSchutz;