import React from "react";

const ThankYou = () => {

        return (
            <section id="thank-you" className="thank-you-section">
                <div className="wrapper">

                    <div className="width95">
                        <div className="row showonstep2 progress_bar">
                            <div className="col-10 col-lg-10 ml-auto mr-auto mb-5 mt-5">
                                <div className="multisteps-form__progress">
                                    <button className="multisteps-form__progress-btn">1. Vertragsangaben</button>
                                    <button className="multisteps-form__progress-btn">2. Versicherungsnehmer</button>
                                    <button className="multisteps-form__progress-btn">3. Zahlungsdetails und Abschluss
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="thank-you-wrapper position-relative thank-wrapper-style-one">

                        <div className="thank-txt text-center">
                            <div className="thank-icon">
                                <img src={require("../assets/img/tht1.png")} alt=""/>

                            </div>
                            <h1>Vielen Dank für Ihren Vertrag</h1>

                            <a className="d-block text-center text-uppercase" href="#">back to page</a>
                        </div>

                    </div>
                </div>
            </section>

        )

}

export default ThankYou;