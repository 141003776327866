import React from "react";

const RateOpSchutz = () => {

    return (
        <div class="container">
            <div class="row">
                <div class="column"  style={{width: '50%', display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'}}><img src="https://www.hethan.de/media/image/3c/ac/01/hethan-logo-lang.jpg"
                                                                                          style={{verticalAlign: 'bottom'}}/></div>
                <div class="column" style={{width: '50%'}}>
                    <img src={require("../assets/img/dfv_logo.jpg")} alt="Snow" />
                </div>
               {/* <div class="column">
                    <img src="img_forest.jpg" alt="Forest" style="width:100%">
                </div>
                <div class="column">
                    <img src="img_mountains.jpg" alt="Mountains" style="width:100%">
                </div>*/}

            </div>
            <div class="row">
        <iframe src="https://makler.deutsche-familienversicherung.de/spezial/tierkrankenschutz/hunde/antrag/?cm_mmc=014407&cIdent=ewogICJmdW5jdGlvbiI6IHsKICAgICJmZWVDYWxjIjogdHJ1ZSwKICAgICJmb3JtRmlsbCI6IHRydWUKICB9LAogICJpbnN1cmFuY2UiOiB7CgkiZXJzdGF0dHVuZyI6IDAKICB9Cn0%3D"
                style={{overflow: 'hidden', height: '170vh', width: '100%'}}></iframe>
        </div>
</div>
    )

}

export default RateOpSchutz;