import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux";
import store from "./app/store";
//import './index.css';
import App from './App';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/bootstrap-datepicker.css';
import './assets/css/fontawesome-all.css';
import './assets/css/animate.min.css';
import 'bootstrap';

//STEP 1:
//create components using React.lazy
const HethanTheme = React.lazy(() => import('./components/hethanTheme'));
const JagdhundeschuleTheme = React.lazy(() => import('./components/jagdhundeschuleTheme'));

const domain = window.location.host;
console.log('domain: ' + domain);

//STEP 2:
//create a parent component that will load the components conditionally using React.Suspense
const ThemeSelector = ({ children }) => {
    return (
        <>
            <React.Suspense fallback={<></>}>
                {(domain === "hethan.integria-insurance.com") && <HethanTheme />}
                {(domain === "jagdhundeschule.integria-insurance.com") && <JagdhundeschuleTheme />}
            </React.Suspense>
            {children}
        </>
    )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <ThemeSelector>
            <App/>
        </ThemeSelector>
    </Provider>
);