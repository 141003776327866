//import "./App.css";
import React, { useState, useEffect } from "react";
import StartPage from "./components/./StartPage";
import RateOpSchutz from "./components/RateOpSchutz";
import Rate60VollSchutz from "./components/Rate60VollSchutz";
import Rate80VollSchutz from "./components/Rate80VollSchutz";
import Rate100VollSchutz from "./components/Rate100VollSchutz";
import PersonalInfo from "./components/PersonalInfo";
import LocationInfo from "./components/LocationInfo";
import OtherInfo from "./components/OtherInfo";
import ThankYou from "./components/ThankYou";
import Layout from "./pages/Layout";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { PaymentContainer } from "./features/payment/Payment";
import { StatusContainer } from "./features/status/Status";

function App() {
  const [page, setPage] = useState(0);

  const [x, setX] = useState(0);

  const [formData, setFormData] = useState({
    service_name: "choice1"
  });

  const initSelectDataOptions = () => {
    var options = [];
    var months1 = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
    for (var i = 1; i <= 12; i++) {
      let month = i, d = new Date();
      d.setMonth(d.getMonth() + month);
      options.push('01-' + months[d.getMonth()] + '-' + d.getFullYear());
    }
    //console.log(options);
    return options;
  }

  const Headline = ({ p }) => {

    useEffect(() => {
      let header = document.getElementsByClassName('progress_bar');

      if (p > 0){
        header[0].classList.remove('showonstep2');

        let btns = document.getElementsByClassName('multisteps-form__progress-btn');

        for (var i = 0; i < btns.length; i++) {
          if (i <= p - 1) {
            btns[i].classList.add('js-active');
          }
        }
      }
      else
        header[0].classList.add('showonstep2');
    });

    return <div className="row showonstep2 progress_bar">
        <div className="col-10 col-lg-10 ml-auto mr-auto mb-5 mt-5">

        </div>
      </div>;
  };

  const componentList = [
    <StartPage
      formData={formData}
      setFormData={setFormData}
      page={page}
      setPage={setPage}
      x={x}
      setX={setX}
    />,

    <PaymentContainer />,
    <ThankYou
    />
  ];
  return (

    <div className="App">
    <BrowserRouter>

      <Headline p={page}/>
      {/*<div className="progress-bar">
        {
          <div
            style={{
              width:
                page === 0
                  ? "25%"
                  : page === 1
                  ? "50%"
                  : page === 2
                  ? "75%"
                  : "100%",
            }}
          ></div>
        }
      </div>*/}


        <div className="wrapper">



        </div>
                   <Routes>
                    <Route path="/op" element={<RateOpSchutz />}/>
                    <Route path="/voll-60" element={<Rate60VollSchutz />}/>
                    <Route path="/voll-80" element={<Rate80VollSchutz />}/>
                    <Route path="/voll-100" element={<Rate100VollSchutz />}/>
                    <Route path="/" element={componentList[page]}/>
                    {/*<Route path="/checkout/:type" element={<PaymentContainer />} />
                     <Route path="/status/:type" element={<StatusContainer />} />*/}
                  </Routes>
                </BrowserRouter>
    </div>
  );
}

export default App;
