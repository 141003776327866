import React from "react";

const Header = () => {
    const domain = window.location.host;

    console.log('domain: ' + domain);
    if (domain === "hethan.integria-insurance.com") {
        return (
            <div class="row">
                <div class="column"  style={{width: '50%', display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'}}><img src="https://www.hethan.de/media/image/3c/ac/01/hethan-logo-lang.jpg"
                                                    style={{verticalAlign: 'bottom'}}/></div>
                <div class="column" style={{width: '50%'}}>
                    <img src={require("../assets/img/dfv_logo.jpg")} alt="Snow" />
                </div>
            </div>
        );
    } else {
        return (
            <div class="row">
                <div class="column"  style={{width: '50%', display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'}}><img src={require("../assets/img/jagdschule_hessling.png")}
                                                    style={{verticalAlign: 'bottom'}}/></div>
                <div class="column" style={{width: '50%'}}>
                    <img src={require("../assets/img/dfv_logo_transparent.png")} alt="Snow" />
                </div>
            </div>
        );
    }

}
export default Header;