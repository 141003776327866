import { useState} from "react";
import DatePicker from "react-datepicker";
import moment from 'moment';

import "react-datepicker/dist/react-datepicker.css";
import format from "date-fns/format";
import { useForm, Controller } from "react-hook-form";
import TextInput from "./TextInput";

const PersonalInfo = ({ formData, setFormData, page, setPage, x, setX, optiondata }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [dateChosen, setDateChosen] = useState(false);
    const { register, trigger, formState: { errors }, control } = useForm({
        mode: 'onChange',
        defaultValues:
            {animalBirthday: (formData.animalBirthday? moment(formData.animalBirthday, 'dd-MM-yyyy').toDate(): '')}
    });

    const handleNext = async (e) => {
        let isValidAnimalBirthday = false;
        /*if (formData.animalBirthday){
            let animalBirthdayDate = moment(formData.animalBirthday,'DD-MM-YYYY').toDate();
            setAnimalBirthday(animalBirthdayDate);
            isValidAnimalBirthday = validateField(animalBirthdayDate);
        } else {*/
            isValidAnimalBirthday = await trigger(['animalBirthday']);

        /*}*/
        let isValid = await trigger(['contractStart', 'animalName', 'gender']);
        if (isValid && isValidAnimalBirthday){
            setPage(page + 1);
        }
    }

    const handleChange = async(e) => {
        setIsOpen(!isOpen);
        let isValid = await trigger(['animalBirthday']);
        if (isValid){
            setDateChosen(true);
            setFormData({ ...formData, 'animalBirthday': format(e, "dd-MM-yyyy") });
        } else {
            setDateChosen(false);
        }
    };

    const handleClick = (e) => {
        e.preventDefault();
        setIsOpen(!isOpen);
    };

    const validateField = (f) => {
        let today = new Date();
        let oldestBirthday = moment(today).subtract(7, 'years').toDate();
        let checkDate = moment(f).isAfter(oldestBirthday);
        return checkDate;
    }

    const { onChange, onBlur, name, value, ref } = register('animalBirthday',
        { required: true, validate: validateField});
    const registerAnimalName = register('animalName', {required: true, minLength:
            {value: 3, message: 'Bitte mindestens 3 Buchstaben.'}});

    return (
        <div className="multisteps-form__panel js-active" data-animation="slideHorz" style={formData}>
            {/* div 2 */}
            <div className="wizard-forms">
                <div className="inner1 pb-100 clearfix">
                    <div className="form-content pera-content">
                        <div className="step-inner-content">
                            {/*<div className="step-progress float-right">
                                <span>1 of 3 completed</span>
                                <div className="step-progress-bar">
                                    <div className="progress">
                                        <div className="progress-bar"></div>
                                    </div>
                                </div>
                            </div>*/}
                            <div className="multisteps-form__progress">
                                <button className="multisteps-form__progress-btn">1. Vertragsangaben</button>
                                <button className="multisteps-form__progress-btn">2. Versicherungsnehmer</button>
                                <button className="multisteps-form__progress-btn">3. Zahlungsdetails und Abschluss
                                </button>
                            </div>
                            <h2>Vertragsangaben</h2>

                            <div className="date-select language-select has-float-label" style={{position: "relative", marginBottom: "3em"}}>
                                <select name="contractStart" className="form-control1" {...register('contractStart', { required: true})}
                                        style={{ border: errors.contractStart ? '2px solid red' : '' }} onChange={(e) =>
                                        {onChange(e);setFormData({ ...formData, contractStart: e.target.value })}}
                                     value={formData.contractStart? formData.contractStart: ''}>
                                    <option value="">Select date</option>
                                    {optiondata.map((it, idx) => {
                                        return (<option key={idx} value={it}>{it}</option>);
                                    })}
                                </select>
                                <label className="title">Vertragsbeginn</label>
                                <div style={{color: 'red'}}>
                                    {errors.contractStart && errors.contractStart.type === "required" && <span>Dieses Feld ist obligatorisch.</span>}
                                </div>

                            </div>

                            <h3>Angaben zu Ihrem Haustier</h3>
                            <div className="step-content-field">
                                <div className="date-picker date datepicker2 has-float-label">
                                    <input id="my-id" type="text" name="date2" className='form-control required'
                                       placeholder='Geburtsdatum' required onClick={handleClick}  onChange={handleChange}
                                       style={{ border: errors.animalBirthday ? '2px solid red' : '' }}
                                    value={dateChosen || formData.animalBirthday? formData.animalBirthday: ''}/>

                                    <label className="title">Geburtsdatum des Tieres</label>
                                    <div style={errors.animalBirthday ? {visibility: 'visible', color: 'red'}: {visibility: 'hidden'}}>Geburtsdatum des Tieres sollte weniger als 7 Jahre zurückliegen.</div>
                                </div>
                                <div style={{color: 'red'}}>
                                    {errors.animalBirthday && errors.animalBirthday.type === "required" && <span>Dieses Feld ist obligatorisch.</span>}
                                </div>
                                <Controller
                                    control={control}
                                    name="animalBirthday"
                                    value={formData.animalBirthday? formData.animalBirthday: ''}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (isOpen &&
                                        <DatePicker selected={new Date()} onChange={(e) => {
                                            onChange(e);
                                            handleChange(e);
                                        }}  inline showYearDropdown portalId="root-portal"
                                                    dateFormatCalendar="MMMM"
                                                    yearDropdownItemNumber={15}
                                                    dateFormat="MM/dd/yyyy"
                                                    scrollableYearDropdown
                                                    placeholderText="Select a weekday"
                                                    value={formData.animalBirthday? formData.animalBirthday: ''}

                                        />
                                    )}
                                />
                            </div>
                            <div className="form-inner-area position-relative has-float-label">
                                <TextInput fieldName='animalName' label='Name Ihres Tieres' register={register} errors={errors}
                                    onChange={registerAnimalName.onChange} setFormData={setFormData} formData={formData}/>
                            </div>
                            <div className="form-inner-area position-relative has-float-label">
                                <div className="badge-selection" style={{ border: errors.gender ? '2px solid red' : '', marginBottom: 0 }}>
                                    <h3>Geschlecht des Tieres:</h3>
                                    <label>
                                        <input type="radio" name="gender" value="Male" {...register('gender', { required: true })}
                                               onChange={(e) => {onChange(e); setFormData({ ...formData, gender: e.target.value })}}
                                               checked={formData.gender == 'Male'? 'checked': ''}
                                        />
                                        <span className="checkmark">männlich</span>
                                    </label>
                                    <label>
                                        <input type="radio" name="gender" value="Female" {...register('gender', { required: true })}
                                               onChange={(e) => {onChange(e); setFormData({ ...formData, gender: e.target.value })}}
                                            checked={formData.gender == 'Female'? 'checked': ''}
                                        />
                                        <span className="checkmark">weiblich</span>
                                    </label>

                                </div>
                                <div style={{color: 'red'}}>
                                    {errors.gender && errors.gender.type === "required" && <span>Dieses Feld ist obligatorisch.</span>}

                                </div>
                            </div>
                            <div className="form-inner-area position-relative has-float-label">
                                <div className="form-inner-area position-relative has-float-label" style={{marginTop:"10px"}}>
                                    <input type="text" className="form-control" name="transponder_code" placeholder="Kennzeichnungsnummer (Transponder-Code) (optional)" />
                                    <label className="title">Transponder-Code</label>
                                </div>
                                <p style={{fontSize:"13px"}}>Sie finden die 15-stellige Kennzeichnungsnummer (Transponder-Code) in Ihrem EU-Heimtierausweis (optional).</p>
                                <p style={{fontSize:"15px"}}>Wichtiger Hinweis: Die Kennzeichnungsnummer (Transponder-Code) muss uns spätestens mit der Bearbeitung Ihres ersten Versicherungsfalls mitgeteilt werden</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<!-- /.inner -->*/}
                <div className="actions">
                    <ul>
                        <li><span className="js-btn-prev" title="BACK" onClick={() => {
                            setPage(page - 1);
                        }}><i className="fa fa-arrow-left"></i> BACK </span></li>
                        <li><span className="js-btn-next" title="NEXT" onClick={handleNext}>NEXT <i className="fa fa-arrow-right"></i></span></li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default PersonalInfo